import React from "react"
import Text from "@bankiru/ui-kit/core/Text"
import { LinkText } from '@bankiru/ui-kit/components/Link/LinkText';

export const data = [
  {
    title: "Если Вы расторгнете договор банковского вклада (закроете вклад) досрочно",
    text:
      <Text color="secondary" size="fs16">
        {"Вы можете сделать это без потери уже начисленных процентов по вкладу – ПАО " +
          "«РОСБАНК» выплатит Вам проценты по установленной договором банковского вклада " +
          "ставке за фактическое количество дней нахождения денежных средств на вкладе до " +
          "31.12.24 г. включительно. Расторгая досрочно вклад с 01.01.25, проценты будут " +
          "выплачены по ставке вклада на 31.12.24 и далее с 01.01.25 г. по ставке до " +
          "востребования. Это отличный способ получить деньги досрочно и вложить их на " +
          "более выгодных условиях без потерь."}
      </Text>
  },
  {
    title: "Как закрыть вклад",
    text: (
      <>
        <Text color="secondary" size="fs16">
          Закрыть вклад можно через колл-центр ПАО «РОСБАНК» по тел. 8 800 234 44 34, в отделении банка или написать заявление и отправить на почту маркетплейса Банки.ру <LinkText href="mailto:client-info@banki.ru\" skin="blue">client-info@banki.ru</LinkText>. После закрытия средства вернутся на ваш Кошелек на маркетплейсе Банки.ру.
        </Text>
        <Text color="secondary" size="fs16">
          Если Вы не желаете закрыть вклад, ПАО «РОСБАНК» передаст его в АО «ТБАНК». Все условия договора банковского вклада сохранятся и будут иметь силу, Вы также сможете увидеть свой вклад в личном кабинете Маркетплейса Банки.ру или через мобильное приложение АО «ТБАНК».  Также по всем вопросам, связанным с Вашим вкладом, пишите в поддержку АО «ТБАНК» — <LinkText href="https://www.tbank.ru/mybank/" target="_blank" skin="blue">в чате в личном кабинете
          </LinkText> или в приложении на вкладке «Чат» → «Поддержка» или обращайтесь по тел. 8 800 333 33 33.
        </Text>
      </>
    ),
  },
]
