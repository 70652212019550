import React, { useContext } from "react"
import PageContext from "../../context/context"
import Text from "@bankiru/ui-kit/core/Text"
import Panel from "@bankiru/ui-kit/core/Panel"
import FlexboxGrid from "@bankiru/ui-kit/core/FlexboxGrid"
import Flexbox from "@bankiru/ui-kit/core/Flexbox"
import { Indent } from "@bankiru/ui-kit/components/Indent"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import { data } from "./data"

export const RosbankHint = () => {
  const { isDesktop, isTablet, isMobile } = useContext(PageContext)

  return (
    <LayoutWrapper id="rosbank">
      <Indent margin={isMobile ? '32 0 0 0' : '80 0 0 0'}>
        <Flexbox flexDirection="column" gap={isMobile ? '16' : '32'}>
          <Text size={isMobile ? 'fs16' : 'fs32'} color="primary" weight="medium">С 01.01.2025 ПАО «РОСБАНК» присоединяется к АО «ТБАНК» в качестве филиала</Text>
          <Flexbox flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? '16' : '32'}>
            {data.map(({ title, text }) => (
              <Flexbox flexBasis="0" flexGrow="1">
                <Panel
                  key={text}
                  sections={
                    <FlexboxGrid direction="vert" gap={isDesktop ? "medium" : "small"}>
                      <Flexbox flexDirection="column" gap={isMobile ? "8" : "16"}>
                        <Text
                          tagName="h2"
                          weight="bold"
                          size={isMobile ? "fs16" : isTablet ? "fs18" : "fs20"}
                        >
                          {title}
                        </Text>
                        <Flexbox flexDirection="column" gap="8">
                          {text}
                        </Flexbox>
                      </Flexbox>
                    </FlexboxGrid>
                  }
                />
              </Flexbox>
            ))}
          </Flexbox>
        </Flexbox>
      </Indent>
    </LayoutWrapper>
  )
}
