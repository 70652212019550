import React, { useEffect, useState } from "react"
import PageContext from "../context/context"
import { Layout } from "./globalStyled"
import LayoutWrapper from "@bankiru/ui-kit/core/LayoutWrapper"
import { Header } from "./Header"
import { PageInfo } from "./PageInfo"
import { About } from "./About"
import { DocumentsSection } from "./DocumentsSection"
import { AccordionSection } from "./AccordionSection"
import { PersonalData } from "./PersonalData"
import { Footer } from "./Footer"
import { getDeviceType } from "../utils/getDeviceType"
import { RosbankHint } from "./RosbankHint"

export const Home = () => {
  const [deviceType, setDeviceType] = useState()

  useEffect(() => {
    setDeviceType(getDeviceType())
  }, [])

  if (deviceType) {
    return (
      <PageContext.Provider value={deviceType}>
        <Layout>
          <Header/>
          <LayoutWrapper>
            <PageInfo/>
            <About/>
          </LayoutWrapper>
          <RosbankHint />
          <DocumentsSection/>
          <AccordionSection/>
          <PersonalData/>
          <Footer/>
        </Layout>
      </PageContext.Provider>
    )
  }

  return null
}
